<template>
  <div>
    <CRow>
      <CCol
        lg="12"
        sm="1"
      >
        <div class="d-flex justify-content-between">
          <select
            id="categorias"
            class="form-select"
            @change="categoryFilterChanged()"
          >
            <option
              v-for="accion in getCategorias()"
              :key="accion.category_id"
            >
              {{ accion.category_name }}
            </option>
          </select>
        </div>
        <CTableWrapper
          :items="datos_show"
        >
          <template #header>
            <div class="d-flex justify-content-between">
              Consejos
              <CButton
                class="btn btn-success"
                size="sm"
                @click="addConsejo()"
              >
                Añadir consejo
              </CButton>
            </div>
          </template>
        </CTableWrapper>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import CTableWrapper from "../../components/TablaConsejos";
import {getCategoriasData} from '../../utils/utils';
import axios from "axios";
import $ from "jquery";
import "core-js/stable";
import "regenerator-runtime/runtime";

/**
 * Función con la que se obtienen los datos de los consejos
 * 
 * @returns {Promise<object[]>} Promesa que al resolverse nos da todos los consejos
 */
async function getConsejoData() {
  let data;
  const token = localStorage.token;
  await axios.get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_CONSEJOS + "all", {
      headers: {
        Authorization: token,
      },
    }).then((result) => {
      data = result.data;
    }).catch(function (error) {
      if (error.response) {
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          window.location = "#/pages/login";
        }
        if (error.response.status == 404) {
          window.location = "#/404";
        }
        if (error.response.status == 500) {
          window.location = "#/500";
        }
      }
    });
  return data;
}

export default {
  name: "ConsejoTable",
  components: { CTableWrapper },
  data() {
    return {
      datos: [],
      temp: 0,
      tempC: 0,
      categorias: [],
      datos_show: [],
    };
  },
  created() {
    //Aquí llamamos al metodo para que se llamen a las funciones y recoger los datos
    this.getCategorias();
  },
  methods: {
    /**
     * Función que toma los datos de los consejos para mostrarlos
     * y los devuelve.
     * 
     * @returns {object[]} Los consejos obtenidos
     */
    getConsejoData() {
      this.datos_show = this.datos;
      return this.datos_show;
    },
    /**
     * Función para recibir los datos que se pasaran al componente de la tabla
     */
    recibirDatos() {
      if (this.temp > 0) {
        return this.datos;
      }
      this.getCategorias();
      let data = getConsejoData();
      data.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }
        this.datos = [];
        for (let index = 0; index < result.length; index++) {
          let element = {};
          element.titulo = result[index].advice_title;
          element.descripcion = result[index].advice_description;
          let cat;
          for (let ind = 0; ind < this.categorias.length; ind++) {
            if (this.categorias[ind].category_id == result[index].category_id) {
              cat = this.categorias[ind].category_name;
              break;
            }
          }
          element.categoria = cat;
          element.acciones = result[index].advice_id;
          this.datos.push(element);
        }
        this.getConsejoData();
      });
      this.temp++;
    },
    /**
     * Función asociada al boton "crear consejo" que nos llevara al formulario
     * de creación de un consejo.
     */
    addConsejo() {
      this.$router.push("/consejo/crearConsejo/");
    },
    /**
     * Función en la que se obtienen las categorías y que también
     * llama al metodo recibirDatos para cargarlos.
     * 
     * @returns {object[]} Las categorías obtenidas.
     */
    getCategorias() {
      if (this.tempC > 0) {
        return this.categorias;
      }
      let data = getCategoriasData();
      data.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }
        this.categorias = [
          {
            id: "Todas",
            category_name: "Todas",
          },
        ];
        this.categorias.push(...result);
        this.tempC++;
        this.recibirDatos();
      });
      return this.categorias;
    },
    /**
     * Función asociada al select que se llama cada vez que se selecciona
     * una categoría.
     */
    categoryFilterChanged() {
      var selectedCategory = $("#categorias").children("option:selected").val();
      this.selectCategorias(selectedCategory);
    },
    /**
     * Función que filtra la tabla para que aparezcan solo
     * los consejos con la categoría indicada.
     * 
     * @param {string} categoria - El nombre de la categoría por la que filtrar.
     */
    selectCategorias(categoria) {
      if (categoria == "Todas") {
        this.datos_show = this.datos;

        return;
      }

      this.datos_show = [];
      for (let i = 0; i < this.datos.length; i++) {
        const element = this.datos[i];
        if (element.categoria == categoria) {
          this.datos_show.push(element);
        }
      }
    },
  },
};
</script>