<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-grid" /> {{ caption }}
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        hover
        striped
        :items="items"
        :fields="fields"
        :items-per-page="5"
        :sorter="{ resetable: true }"
        :items-per-page-select="{ label: 'Consejos por página:',
                                  values: [
                                    5,
                                    10,
                                    25,
                                    50,
                                    100,
                                    250,
                                    500
                                  ]
        }"
        :table-filter="{label: 'Filtro:', placeholder: 'Comience a escribir...'}"
        :no-items-view="{noResults: 'No se han encontrado datos', noItems: 'No se han encontrado datos que cumplan las características indicadas'}"
        pagination
      >
        <template #titulo="{ item }">
          <td>
            <a
              class="text-info"
              @click="informacionConsejo(item.acciones)"
            >{{
              item.titulo
            }}</a>
          </td>
        </template>

        <template #acciones="{ item }">
          <td>
            <CButton
              color="success"
              shape="pill"
              size="sm"
              style="margin-right: 3px;"
              @click="editConsejo(item.acciones)"
            >
              Editar
            </CButton>
            <CButton
              color="danger"
              shape="pill"
              size="sm"
              @click="botonEliminar(item)"
            >
              Eliminar
            </CButton>
            <CModal
              id="modalElim"
              title="Eliminar consejo"
              color="principal"
              :show.sync="deleteModal"
            >
              <p class="text-muted">
                ¿Está seguro de que desea eliminar el consejo titulado
                {{ consejo.titulo }}? Esta acción no se puede deshacer.
              </p>
              <div slot="footer">
                <CButton
                  color="primary"
                  @click="deleteConsejo(consejo.acciones)"
                >
                  Aceptar
                </CButton>
                <CButton
                  color="danger"
                  @click="deleteModal = false"
                >
                  Volver
                </CButton>
              </div>
            </CModal>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>

<script>
import axios from "axios";
import "core-js/stable";
import "regenerator-runtime/runtime";

export default {
  name: "Table",
  props: {
    items: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      default() {
        return [
          {
            key: "titulo",
            label: "Título"
          },
          {
            key: "descripcion",
            label: "Descripción"
          },
          {
            key: "categoria",
            label: "Categoría"
          },
          {
            key: "acciones",
            sorter: false,
            filter: false
          }];
      },
    },
    caption: {
      type: String,
      default: "Table",
    },
  },
  data() {
    return {
      deleteModal: false,
      consejo: {},
    };
  },
  methods: {
    /**
     * Este es el evento que se ejecuta al hacer click en el boton de eliminar de la tabla acciones
     * Eso hace que se abra el modal
     * 
     * @param {object} consejo - El consejo sobre el que se ha llamado al evento
     */
    botonEliminar(consejo) {
      this.consejo = consejo;
      this.deleteModal = true;
    },
    /**
     * Este evento de click, se ejecuta al hacer click en el boton de editar de la tabla acciones
     * te lleva a la página para editar el consejo
     * 
     * @param {number} id - El ID del consejo sobre el que se ha llamado el evento editar
     */
    editConsejo(id) {
      this.$router.push("/consejo/editConsejo/" + id);
    },
    /**
     * Este metodo eliminará el consejo, mandando por la URL el id
     * 
     * @param {number} id - El id del cconsejo a eliminar
     */
    deleteConsejo(id) {
      const token = localStorage.token;
      axios.delete(
          process.env.VUE_APP_URL + process.env.VUE_APP_URL_CONSEJOS + id,
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then(() => {
          //con esto actualizamos la página para que se actualice la tabla
          location.reload();
        }).catch(function (error) {
          if (error.response) {
            if (error.response.status == 401) {
              localStorage.removeItem("token");
              window.location = "#/pages/login";
            }
            if (error.response.status == 404) {
              window.location = "#/404";
            }
            if (error.response.status == 500) {
              window.location = "#/500";
            }
          }
        });
      this.deleteModal = false;
    },
    /**
     * Este metodo, que se ejecuta al hacer click en el nombre del consejo, nos llevará a la página para ver la información del consejo
     * 
     * @param {number} id - El Id del consejo sobre el que se llama el evento
     */
    informacionConsejo(id) {
      this.$router.push("/consejo/infoConsejo/" + id);
    },
  },
};
</script>
